import { FunctionComponent, h } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { EpisodeContext } from "../../providers/episode";

interface SharePanelProps {
  visible: boolean;
  closeAction: () => void;
}

type ShareCode = { currentEpisode: number, viewedEpisodes: string[] };

const SharePanel: FunctionComponent<SharePanelProps> = ({
  visible,
  closeAction,
}) => {
  const {
    episodes,
    viewedEpisodes,
    currentEpisode,
    setCurrentEpisode,
    setViewedEpisodesDirectly,
  } = useContext(EpisodeContext);
  const [showPanel, setPanelShown] = useState<boolean>(visible);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [shareCode, setShareCode] = useState<string>('');
  const timer = useRef<number>(0);

  useEffect(() => {
    setShareCode(
      btoa(
        JSON.stringify({
          viewedEpisodes,
          currentEpisode,
        })
      )
    );
  }, [viewedEpisodes, currentEpisode]);

  useEffect(() => {
    if (visible) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => setPanelShown(visible), 500);
    }
  }, [visible]);

  const closePanel = () => {
    setPanelShown(false);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => closeAction(), 500);
  };

  const testShareCode = (data: ShareCode) => {
    const currentOK = episodes.find(ep => ep.order === data.currentEpisode);
    const viewedOK = data.viewedEpisodes.filter(id => !episodes.find(ep => ep.id === id)).length === 0;
    return currentOK && viewedOK;
  };

  const handleCodeChange = (event: Event) => {
    const target = event.currentTarget as HTMLTextAreaElement;
    try {
      const data = JSON.parse(atob(target.value)) as ShareCode;
      if (testShareCode(data)) {
        setError(false);
        setShareCode(target.value);
      } else {
        setError(true);
        setSuccess(false);
      }
    } catch(error) {
      setError(true);
      setSuccess(false);
    }
  };

  const parseCode = () => {
    const parsedData = JSON.parse(atob(shareCode)) as ShareCode;
    if (testShareCode(parsedData)) {
      setCurrentEpisode(parsedData.currentEpisode);
      setViewedEpisodesDirectly(parsedData.viewedEpisodes);
      setSuccess(true);
    }
  };

  return visible ? (
  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div
        class={[
          'absolute inset-0 use-bg transition-opacity ease-in-out duration-500',
          showPanel ? 'opacity-100' : 'opacity-0'
        ].join(' ')}
        aria-hidden="true">
      </div>
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
        <div class={[
          'relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700',
          showPanel ? 'translate-x-0' : 'translate-x-full',
        ].join(' ')}>
          <div class={[
            'absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4 ease-in-out duration-500',
            showPanel ? 'opacity-100' : 'opacity-0'
          ].join(' ')}>
            <button
              onClick={closePanel}
              class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
              <span class="sr-only">Close panel</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
            <div class="px-4 sm:px-6">
              <h2
                id="slide-over-heading"
                class="text-lg font-medium text-gray-900">
                Share your viewing history
              </h2>
            </div>
            <div class="mt-6 relative flex-1 px-4 sm:px-6">
              <div class="absolute inset-0 px-4 sm:px-6">
                <label
                  for="code"
                  class="block text-sm font-medium text-gray-700">
                  Copy or paste your code:
                </label>
                <textarea
                  id="code"
                  name="code"
                  onKeyUp={handleCodeChange}
                  rows={10}
                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full px-4 py-2 sm:text-sm border border-gray-300 rounded-md resize-none">{shareCode}</textarea>
                <div class="flex flex-col sm:flex-row-reverse justify-between mt-4">
                  <button
                    onClick={parseCode}
                    disabled={error}
                    class={[
                      'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
                      error
                        ? 'bg-gray-300 focus:ring-gray-500 text-gray-500'
                        : 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white'
                    ].join(' ')}>
                    { success ? 'Saved Succesfully' : 'Save' }
                  </button>
                  { error && <p class="py-2 mb-2 sm:mb-auto sm:mr-2 text-sm text-red-500 font-medium">
                    This input could not be parsed correctly!
                  </p> }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  ) : null;
};

export default SharePanel;