import { FunctionalComponent, h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";
import { useContext, useEffect, useState } from "preact/hooks";
import EpisodeProvider, { EpisodeContext } from "../providers/episode";

import About from "../routes/about";
import Episode from "../routes/episode";
import Redirect from "../routes/redirect";
import Header from "./header";
import SharePanel from "./share-panel";

interface WrapperProps {
  onRouteChange: (e: RouterOnChangeArgs) => any;
}

const Wrapper: FunctionalComponent<WrapperProps> = ({ onRouteChange }: WrapperProps) => {
  const {episodes, currentEpisode} = useContext(EpisodeContext);
  const [hasEpisodes, setEpisodes] = useState<boolean>(false);
  const [panelVisible, setPanelVisible] = useState<boolean>(false);

  const togglePanelVisible = () => {
    setPanelVisible(!panelVisible);
  };
  
  useEffect(() => {
    setEpisodes(episodes.length > 0);
  }, [episodes]);

  return hasEpisodes
    ? (
      <div id="app" class="use-bg h-screen bg-gray-900 flex flex-col items-center">
        <Header shareAction={togglePanelVisible} />
        <Router onChange={onRouteChange}>
          <Redirect path="/" to="/episode/" default />
          <Redirect path="/episodes" to="/episode" />
          <Route path="/episode/" component={Episode} episodeNumber={currentEpisode} />
          <Route path="/episode/:episodeNumber" component={Episode} />
          <Route path="/about" component={About} />
        </Router>
        <SharePanel visible={panelVisible} closeAction={togglePanelVisible} />
      </div>
    )
    : null;
}

const App: FunctionalComponent = () => {
  let currentUrl: string;
  const handleRoute = (e: RouterOnChangeArgs) => {
    console.log(e.url);
    currentUrl = e.url;
  };

  return (
    <EpisodeProvider>
      <Wrapper onRouteChange={handleRoute} />
    </EpisodeProvider>
  );
};

export default App;
