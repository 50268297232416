import { FunctionComponent, h } from "preact";
import { Link } from "preact-router/match";
import { useState } from "preact/hooks";

interface HeaderProps {
  shareAction: () => void;
}

const Header: FunctionComponent<HeaderProps> = ({
  shareAction,
}) => {
const [menuOpen, setMenuOpen] = useState<boolean>(false);
  return (
  <nav class="flex-none w-full">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          { /* - Mobile menu button- */ }
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-expanded="false"
            onClick={() => setMenuOpen(!menuOpen)}>
            <span class="sr-only">Open main menu</span>
            { /* - Icon when menu is closed. - */ }
            <svg
              className={`${menuOpen ? 'hidden' : 'block'} h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            { /* - Icon when menu is open. - */ }
            <svg
              className={`${menuOpen ? 'block' : 'hidden'} h-6 w-6`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center justify-items-center text-white hover:text-blue-300">
            <svg
              class="w-8 h-8 pt-2 transform rotate-90"
              fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
            <h1 class="block h-8 w-auto text-white text-2xl font-semibold">
              Defiant
            </h1>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <Link
                className="text-gray-300 hover:bg-gray-700 hover:text-white hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
                activeClassName="bg-gray-900 text-white"
                href="/episode/">
                Watch Next
              </Link>
              <Link
                className="text-gray-300 hover:bg-gray-700 hover:text-white hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
                activeClassName="bg-gray-900 text-white"
                href="/about">
                About
              </Link>
            </div>
          </div>
        </div>
        <div class="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <button
            onClick={shareAction}
            class="p-1 rounded-full text-gray-400 hover:text-white hover:bg-gray-700 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">
              Open sharing modal
            </span>
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </div>

    { /* Mobile menu, toggle classes based on menu state. */ }
    <div className={`${menuOpen ? 'block' : 'hidden'} sm:hidden`}>
      <div class="px-2 pt-2 pb-3 space-y-1">
        <Link
          className="text-gray-300 hover:bg-gray-700 hover:text-white hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-900 text-white"
          href="/episode/">
          Watch Next
        </Link>
        <Link
          className="text-gray-300 hover:bg-gray-700 hover:text-white hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium"
          activeClassName="bg-gray-900 text-white"
          href="/about">
          About
        </Link>
      </div>
    </div>
  </nav>
  );
};

export default Header;
