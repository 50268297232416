import { createContext, FunctionComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Axios from 'axios';

export type Episode = {
  id:       string;
  order:     number;
  title:     string;
  duration:  string;
  score:     number;
  season:    number;
  episode:   number;
  metadata:  Metadata;
  series:    Series;
}

interface Metadata {
  special:   Special | null;
  arc:       number[];
  crossover: Series | null;
}

export enum Series {
  DS9 = "DS9",
  DSC = "DSC",
  ENT = "ENT",
  TNG = "TNG",
  TOS = "TOS",
  VOY = "VOY",
}

export enum Special {
  MOVIE  = "Movie",
  TERRAN = "Terran",
}

interface EpisodeContext {
  currentEpisode: number;
  setCurrentEpisode: (val: number) => void;
  episodes: Episode[];
  viewedEpisodes: string[];
  isFirst: (id: number) => boolean,
  isLast: (id: number) => boolean,
  isViewed: (id: string) => boolean,
  toggleEpisodeViewed: (id: string) => void;
  setViewedBefore: (id: string) => void;
  setViewedEpisodesDirectly: (ids: string[]) => void;
}

const initialContext: EpisodeContext = {
  currentEpisode: 0,
  setCurrentEpisode: () => {},
  episodes: [],
  viewedEpisodes: [],
  isFirst: () => false,
  isLast: () => false,
  isViewed: () => false,
  toggleEpisodeViewed: () => {},
  setViewedBefore: () => {},
  setViewedEpisodesDirectly: () => {},
};

export const EpisodeContext = createContext<EpisodeContext>(initialContext);

const hasWindow = (typeof window !== "undefined");

const EpisodeProvider: FunctionComponent = ({ children }) => {
  const [viewedEpisodes, setViewedEpisodes] = useState<string[]>(
    hasWindow
      ? JSON.parse(localStorage.getItem('viewedEpisodes') || '[]')
      : []
  );
  const [currentEpisode, setCurrentEpisode] = useState<number>(
    hasWindow
      ? JSON.parse(localStorage.getItem('currentEpisode') || '1')
      : 1
  );
  const [episodes, setEpisodes] = useState<Episode[]>([]);

  const isFirst = (id: number) => episodes[0].order === id;
  const isLast = (id: number) => episodes[episodes.length -1].order === id;
  const isViewed = (id: string) => viewedEpisodes.indexOf(id) !== -1;

  const toggleEpisodeViewed = (id: string) => {
    const indexes = [...viewedEpisodes];
    const storedIndex = indexes.indexOf(id);
    if (storedIndex === -1) {
      setViewedEpisodes([...indexes, id]);
    } else {
      indexes.splice(storedIndex, 1);
      setViewedEpisodes([...indexes]);
    }
  };

  const setViewedBefore = (id: string) => {
    const indexInEpisodes = episodes.findIndex((ep: Episode) => ep.id === id);
    const episodesToHandle = [...episodes]
      .splice(0, indexInEpisodes + 1)
      .map((ep: Episode) => ep.id);
    setViewedEpisodes(Array.from(new Set([...viewedEpisodes,...episodesToHandle])));
  };

  const setViewedEpisodesDirectly = (episodes: string[]) => {
    setViewedEpisodes([...episodes]);
  };

  useEffect(() => {
    (async () => {
      const req = await Axios.get('/assets/data.json');
      setEpisodes(req.data);
    })();
  }, []);

  useEffect(() => {
    if (hasWindow) {
      localStorage.setItem('viewedEpisodes', JSON.stringify(viewedEpisodes));
      const nextEpisode = episodes
        .sort((a, b) => a.order - b.order)
        .find(ep => !viewedEpisodes.includes(ep.id));
      if (nextEpisode) {
        setCurrentEpisode(nextEpisode.order);
      }
    }
  }, [viewedEpisodes]);

  useEffect(() => {
    if (hasWindow) {
      localStorage.setItem('currentEpisode', JSON.stringify(currentEpisode));
    }
  }, [currentEpisode]);

  const context: EpisodeContext = {
    currentEpisode,
    setCurrentEpisode,
    episodes,
    viewedEpisodes,
    isFirst,
    isLast,
    isViewed,
    toggleEpisodeViewed,
    setViewedBefore,
    setViewedEpisodesDirectly,
  };

  return (
    <EpisodeContext.Provider value={context}>
      { children }
    </EpisodeContext.Provider>
  );
};

export default EpisodeProvider;